#center {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2; /* Ensure the content appears above the blur overlay */
}

#icon {
  display: block;
  width: 99%;
  max-width: 750px;
  max-height: 150px;
  padding-top: 2px;
  margin-left: auto;
  margin-right: auto;
}

#head {
  margin: 0;
  padding: 0;
  background: var(--sec);
}

.galleryContainer {
  padding-left: 10px;
  padding-right: 10px;
}

/* CSS for gallery */
#gallery {
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  width: 30em;
  height: 20em; /* Adjust height as needed */
  display: flex;
  justify-content: center; /* Center slides horizontally */
  margin-bottom: 15px;
}

#slides {
  display: flex;
  position: relative;
  justify-content: center; /* Center slides horizontally */
  align-items: center;
  width: auto;
  height: 100%;
}

.section {
  width: 100%;
  margin: 10px;
  background-color: var(--pri);
  display: flex;
  flex-direction: column; /* Stack children vertically */
  justify-content: center;
  align-items: center;
  z-index: 2; /* Ensure the content appears above the blur overlay */
}
.slide {
  width: auto;
  height: 100%;
  position: absolute; /* Ensure slides stack on top of each other */
  left: 50%; /* Center slide horizontally */
  transform: translateX(-50%); /* Correct alignment horizontally */
  transition: opacity 0.5s ease, visibility 0.5s ease;
  opacity: 0;
}

.slide.active {
  opacity: 1;
  visibility: visible;
}

.socialLogo {
  width: 100px;
  height: 100px;
  margin-left: 10px;
  margin-right: 10px;
}

#nextBtn,
#prevBtn {
  font-size: 2em;
  width: 48%;
  margin: 0;
  margin-bottom: 10px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background: var(--tri);
  text-align: center;
  cursor: pointer;
  transition: background 0.3s, transform 0.2s;
}

#nextBtn {
  float: right;
}

#prevBtn {
  float: left;
}

#nextBtn:hover,
#prevBtn:hover {
  background: var(--pri);
}

#nextBtn:active,
#prevBtn:active {
  transform: scale(0.95);
}

#nextBtn,
#prevBtn {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 500px) {
  #gallery {
    width: 25em;
    height: 15em; /* Adjust height as needed */
  }
}
