@font-face {
  font-family: "Melodrama-Light";
  src: url("Melodrama-Light.otf");
}

@font-face {
  font-family: "Melodrama-Medium";
  src: url("Melodrama-Medium.otf");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Melodrama-Light;
}

:root {
  /* Setting variables so it's easier to change what colour scheme we want on the fly (＾＾)ｂ */
  --pri: #fdf9f3;
  --sec: #b9e2b9;
  --tri: #f4e7e2;
}

content {
  max-width: 100%;
  max-height: 100%;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: auto;
  background: var(--sec);
  position: relative;
}

nav {
  background: var(--sec);
  width: 100%;
  display: flex;
  justify-content: center; /* Center the content inside the nav */
  padding: 5px; /* Adjust padding */
  font-family: Melodrama-Medium;
  font-size: 2em;
  height: 2.3em;
  border-bottom: 5px solid var(--pri);
}

p {
  width: 50%;
  font-size: x-large;
  margin-bottom: 5px;
}

h2 {
  border-bottom: var(--pri) solid 2px;
  margin-bottom: 1px;
}

#buttons {
  display: flex;
  justify-content: center; /* Center the buttons horizontally */
  gap: 10px; /* Adjust the gap between buttons as needed */
  width: 100%; /* Ensure the buttons container takes full width */
}

#buttons a {
  padding: 5px;
  text-decoration: none;
  color: black;
  display: block; /* Ensures the padding affects the entire clickable area */
  text-align: center;
  margin: 0; /* Remove margin to center the buttons correctly */
  width: 25%; /* Adjust width as necessary */
  border-radius: 5px;
  padding: 5px;
}

nav div {
  display: flex;
  justify-content: center; /* Center the nav content */
  width: 100%;
  margin: 0;
  border: none;
  border-radius: 5px;
}
nav div a:hover {
  background: var(--tri);
}

.content-container {
  display: inline-block;
  text-align: center;
  margin-bottom: 20px; /* Adjust as needed for spacing between h1, image, and the Facebook group */
}

h1 {
  margin-bottom: 10px; /* Adjust as needed for spacing between h1 and image */
  display: inline-block;
  vertical-align: middle;
  font-size: 4em;
  text-shadow: 2px 0 white, 0 -2px white, -2px 0 white, 0 2px white;
}

#center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
}

.icon {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  height: 10em;
  width: 10em;
}

#content-wrapper {
  background-size: cover;
  position: relative;
  height: 100%;
  width: 100%;
}
#main {
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh; /* Use viewport height to make it fill the entire screen */
}

.blur-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(0);
  z-index: 1;
  transition: backdrop-filter 0.3s ease-in-out;
  -webkit-transition: -webkit-backdrop-filter 0.3s ease-in-out;
  backdrop-filter: blur(0); /* Initial blur */
  -webkit-backdrop-filter: blur(0); /* For Safari */
}

@media (max-width: 809px) {
  h1 {
    font-size: 3em;
  }
  nav {
    background: var(--sec);
    font-size: 1.5em; /* Adjusted font size for medium screens */
    height: auto; /* Adjust height for responsive design */
  }
  #content-wrapper {
    background: var(--sec);
  }
  p {
    width: 85%;
  }
}

@media (max-width: 768px) {
  h1 {
    font-size: 3em;
  }
  nav {
    background: var(--sec);
    font-size: 1.5em; /* Adjusted font size for medium screens */
    height: auto; /* Adjust height for responsive design */
  }
  #content-wrapper {
    background: var(--sec);
  }
  p {
    width: 85%;
  }
}

@media (max-width: 655px) {
  h1 {
    font-size: 2.5em;
  }
  nav {
    background: var(--sec);
    font-size: 1.5em; /* Adjusted font size for medium screens */
    height: auto; /* Adjust height for responsive design */
  }
  #content-wrapper {
    background: var(--sec);
  }
  p {
    width: 85%;
  }
}

@media (max-width: 578px) {
  h1 {
    font-size: 2em;
  }
  nav {
    background: var(--sec);
    font-size: 1.5em; /* Adjusted font size for medium screens */
    height: auto; /* Adjust height for responsive design */
  }
  #content-wrapper {
    background: var(--sec);
  }
  p {
    width: 85%;
  }
}

@media (max-width: 500px) {
  .icon {
    width: 5em;
    height: 5em;
  }
  nav {
    background: var(--sec);
    font-size: 1em; /* Further adjusted font size for smaller screens */
    height: auto; /* Adjust height for smaller screens */
  }
  #content-wrapper {
    background: var(--sec);
  }
  p {
    width: 85%;
  }
}

@media (max-width: 413px) {
  .icon {
    width: 2.5em;
    height: 2.5em;
  }
  nav {
    background: var(--sec);
    font-size: 1em; /* Further adjusted font size for smaller screens */
    height: auto; /* Adjust height for smaller screens */
  }
  #content-wrapper {
    background: var(--sec);
  }
  p {
    width: 85%;
  }
}
